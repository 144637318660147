export const Members = [
  {
    title: "Sallin Koutev",
    img: "Sallin.webp",
    linkedin: "https://www.linkedin.com/in/sallin-koutev-216b0016b/",
    lastPosition: "",
  },
  {
    title: "Caitlin Syho",
    img: "Caitlin.webp",
    linkedin: "https://www.linkedin.com/in/caitlinsyho/",
    lastPosition: "",
  },

  {
    title: "Kate Simone",
    img: "Kate.webp",
    linkedin: "https://www.linkedin.com/in/kate-simone-866335284/",
    lastPosition: "",
  },
  {
    title: "Eugene Zhu",
    img: "Eugene.webp",
    linkedin: "https://www.linkedin.com/in/eugenezhu/",
    lastPosition: "",
  },

  {
    title: "Duc Dang",
    img: "blueprint.webp",
    linkedin: "https://www.linkedin.com/in/ddang2001/",
    lastPosition: "",
  },

  {
    title: "Supriya Dua",
    img: "Supriya.webp",
    linkedin: "https://www.linkedin.com/in/supriya-dua-a8a576204/",
    lastPosition: "",
  },
  {
    title: "Peiman Zhiani",
    img: "Peiman.webp",
    linkedin: "https://www.linkedin.com/in/peiman-zhiani/",
    lastPosition: "",
  },

  {
    title: "Abishek Deshpande",
    img: "blueprint.webp",
    linkedin: "https://www.linkedin.com/in/abhishek-deshpande-a01132179/",
    lastPosition: "",
  },
  {
    title: "Jeffrey Wong",
    img: "Jeffrey.webp",
    linkedin: "https://www.linkedin.com/in/wong-jeffrey/",
    lastPosition: "",
  },
  {
    title: "Brayden Sue",
    img: "Brayden.webp",
    linkedin: "",
    lastPosition: "",
  },
  {
    title: "Tulika Varma",
    img: "Tulika.webp",
    linkedin: "",
    lastPosition: "",
  },
  {
    title: "Kousha Amouzesh",
    img: "Kousha.webp",
    linkedin: "https://www.linkedin.com/in/kousha-amouzesh/",
    lastPosition: "",
  },
  {
    title: "Iris Au-Yeung",
    img: "blueprint.webp",
    linkedin: "https://www.linkedin.com/in/irisau-yeung/",
    lastPosition: "",
  },

  {
    title: "Gursewak Singh",
    img: "Gursewak.webp",
    linkedin: "https://www.linkedin.com/in/gursewak-singh-310a111a4/",
    lastPosition: "",
  },
  {
    title: "Meghna Rajamohan",
    img: "Meghna.webp",
    linkedin: "http://www.linkedin.com/in/meghna-rajamohan-a787452a7",
    lastPosition: "",
  },
  {
    title: "Samuel Miranda",
    img: "Samuel.webp",
    linkedin: "https://www.linkedin.com/in/samuel-antunes-467a0a214",
    lastPosition: "",
  },
  {
    title: "Hinako Kamiya",
    img: "Hinako.webp",
    linkedin: "https://www.linkedin.com/in/hinako-kamiya/",
    lastPosition: "",
  },
  {
    title: "Vinh Dang",
    img: "Quang.webp",
    linkedin: "https://www.linkedin.com/in/quang-vinh-dang-788ab0194/",
    lastPosition: "",
  },
  {
    title: "Ayana Hussain",
    img: "Ayana.webp",
    linkedin: "https://www.linkedin.com/in/ayana-hussain-a3399a227/",
    lastPosition: "",
  },
  {
    title: "Amy Zhao",
    img: "Amy.webp",
    linkedin: "https://www.linkedin.com/in/amy-zhao-/",
    lastPosition: "",
  },
  {
    title: "Johnson Luong",
    img: "Johnson.webp",
    linkedin: "https://www.linkedin.com/in/johnson-luong/",
    lastPosition: "",
  },
  {
    title: "Derek Huang",
    img: "Derek.webp",
    linkedin: "https://www.linkedin.com/in/derekhuang1/",
    lastPosition: "",
  },
  {
    title: "Duy Nguyen",
    img: "Duy.webp",
    linkedin: "https://www.linkedin.com/in/duwe-ng/",
    lastPosition: "",
  },
  {
    title: "Monishka Gautam",
    img: "Monishka.webp",
    linkedin: "https://www.linkedin.com/in/monishka-gautam/",
    lastPosition: "",
  },
  {
    title: "Zahila Rehman",
    img: "Zahila.webp",
    linkedin: "https://www.linkedin.com/in/zahila-rehman-9118a4217/",
    lastPosition: "",
  },
  {
    title: "Fabian Siswanto",
    img: "Fabian.webp",
    linkedin: "https://www.linkedin.com/in/fabiansiswanto/",
    lastPosition: "",
  },
  {
    title: "Mrinal Goshalia",
    img: "Mrinal.webp",
    linkedin: "https://www.linkedin.com/in/mrinal-goshalia/",
    lastPosition: "",
  },
  {
    title: "Jai Malhi",
    img: "Jai.webp",
    linkedin: "https://www.linkedin.com/in/jaimalhi/",
  },
];
